import axios, { AxiosResponse } from "axios"

import { FC, FormEventHandler, useCallback, useState } from "react"
import { toast } from "react-toastify"

import useFirebase from "@/lib/firebase"
import { useMembershipBooking } from "@/lib/hooks/useMembershipBooking"

import { DialogContent, DialogHeader } from "@/components/ui/dialog"

import { Calendar } from "./ui/calendar"

const NonRecurringDialog: FC = () => {
  const { auth } = useFirebase()
  const [message, setMessage] = useState("Enter email and starting date")
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState<string | undefined>(undefined)
  const [btnText, setBtnText] = useState("BOOK MEMBERSHIP")
  const [newEmail, setNewEmail] = useState(false)
  const [date, setDate] = useState<Date | undefined>(new Date())
  const { buttonLoading, handleUnauthenticatedBooking, membershipItems } =
    useMembershipBooking()

  const dialogClose = () => {
    document.getElementById("closeDialog")?.click()
  }

  const makeBooking = useCallback(async () => {
    setMessage("Please wait...")

    try {
      await handleUnauthenticatedBooking(date!, email!)
      setLoading(false)
    } catch (error: any) {
      toast.error(error.message || "Could not get payment link")
      setLoading(false)
    }

    dialogClose()
  }, [date, email, handleUnauthenticatedBooking])

  const checkForMembership = useCallback(
    async (em: string) => {
      setLoading(true)
      const { data } = (await axios.get(
        `/api/gymMembershipExists?email=${em}`,
      )) as AxiosResponse<{
        exists: boolean
      }>

      if (!data.exists) {
        setMessage(
          "No membership found with this e-mail. Want to try another e-mail?",
        )
        setBtnText("Use this email anyway")
        setNewEmail(true)
      } else {
        await makeBooking()
      }
      setLoading(false)
    },
    [makeBooking],
  )

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    if (!email) {
      toast.error("Email is required")
      return
    }
    if (!date) {
      toast.error("Date is required")
      return
    }

    if (newEmail) {
      // login(email)
      const host = window.location.host
      const url = host.includes("localhost")
        ? `http://${host}/login/verify?redirectTo=/signup?selected=${membershipItems[0]}`
        : `https://${host}/login/verify?redirectTo=/signup?selected=${membershipItems[0]}`
      // console.log(url)
      try {
        const { sendSignInLinkToEmail } = await import("firebase/auth")
        sendSignInLinkToEmail(auth, email, {
          url,
          handleCodeInApp: true,
        }).then(() => {
          window.localStorage.setItem("email", email)
          setLoading(false)
          setEmail("")
          setMessage("Please check your email")
          setBtnText("Get Magiclink")
          setNewEmail(false)
          toast.success("Magiclink has been sent to your email.")
        })
        //@ts-ignore
        // document.querySelector("#dialog-close")?.click()
      } catch (error) {
        toast.error(
          (error as { message?: string }).message ||
            "Something went wrong. Please try later",
        )
      }
      // router.push("/login")
      // window.location.replace(cart?.checkoutUrl!)
    } else {
      checkForMembership(email)
    }
    // login(email)
  }

  return (
    <>
      <DialogContent>
        <DialogHeader>
          <h1 className="font-display max-w-[320px] mb-[30px]  text-xl">
            {message}
          </h1>
          <form
            onSubmit={handleSubmit}
            className="mt-4 overflow-hidden rounded-lg"
          >
            <input
              className="w-full text-xs mb-6 md:text-base px-4 flex items-center h-[50px] bg-gray-200 rounded-lg"
              placeholder="Email"
              type="email"
              value={email}
              onChange={(e) => {
                setNewEmail(false)
                setEmail(e.target.value)
              }}
            />

            <Calendar
              mode="single"
              selected={date}
              onSelect={setDate}
              className="mx-auto border rounded-md w-min"
              fromDate={new Date()}
            />

            {/* <button
              type="submit"
              disabled={loading}
              className="absolute flex justify-center items-center h-[50px]   top-0 bg-cover bg-no-repeat group-hover:bg-[url('/icons/skewed-left-black.svg')] disabled:pointer-events-none disabled:opacity-40 w-[120px] md:w-[168.55px] right-0 bg-[url('/icons/skewed-left-yellow.svg')]"
            >
              <span className="text-xs font-bold md:text-lg group-hover:text-white">
                {btnText}
              </span>
            </button> */}
            <div className="flex justify-center">
              <button
                aria-label="submit"
                type="submit"
                disabled={loading || buttonLoading}
                className={
                  " rounded-[10px] disabled:opacity-30 disabled:pointer-events-none mt-8 -skew-x-[21deg] inline-block font-bold transition-colors duration-300 ease-out hover:text-brand hover:bg-text text-text bg-brand"
                }
              >
                <div className="inline-block px-[80px] pt-[16px] pb-[14px] skew-x-[21deg]">
                  {btnText}
                </div>
              </button>
            </div>
          </form>
        </DialogHeader>
      </DialogContent>
    </>
  )
}

export default NonRecurringDialog
